import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appBlockPaste]'
})
export class BlockPasteDirective {

  constructor() { }

  @HostListener('paste', ['$event']) onPaste(event: any) {
    event.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

}
