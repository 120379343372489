<section class="hello-dark" *ngIf="pdv">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-xl">
                <!-- Saludo -->
                <h3>Hola</h3>
                <!-- Nombre de tienda -->
                <h6 *ngIf="pdv && pdv?.info?.tipo_vista !== PDV_MULTIMARCA">
                    {{ pdv.info.name }}
                </h6>
            </div>

            <div class="col-12 col-xl-auto">
                <div class="my-2 my-lg-0 menu--tienda">
                    <!-- Ficha del comercial -->
                    <div class="gestor1" *ngIf="pdv && pdv.gpv && pdv?.info?.tipo_vista !== PDV_MULTIMARCA">
                        <!-- Foto del comercial -->
                        <!--<img src="./assets/img/user.png" alt="Person" width="72" height="72">-->
                        <!-- Nombre del comercial -->
                        <a style="cursor: pointer;" (click)="openGpvDialog()">
                            <b>Tu GPV: </b>
                            <span>{{ pdv.gpv.name }}</span>
                            <!-- Conectado --><span class="dot--desconnect gray"></span>
                            <!-- Desconectado --><!--span class="dot--connect"></span-->
                        </a>
                    </div>

                    <div class="gestor-support">
                        <a href="javascript:void(0)" (click)="openSupportDialog()" class="icons--nav">
                            <img src="./assets/img/phone1.svg" class="img-fluid" width="14">
                            <strong>¿Necesitas ayuda?</strong>
                        </a>
                    </div>

                    <div class="tooltips" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || permSv.hasPermFromV2(allPermissions.rep_movil.permite_ventas) || (permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) && permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional))">
                        <!-- Link externo -->
                        <a href="javascript:void(0)" (click)="openManualsDialog()" class="icons--nav">
                            <img src="./assets/img/help-circle1.svg" class="img-fluid icon-nav" width="22">
                        </a>
                        <span class="tooltiptext">Manuales</span>
                    </div>

                    <div class="tooltips" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) || permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                        <a href="javascript:void(0)" (click)="openNotificationsDialog()" class="icons--nav">
                            <img src="./assets/img/bell1.svg" class="img-fluid icon-nav" width="23">
                        </a>
                        <span class="tooltiptext">Comunicados</span>
                        <!-- aviso visual de alerta -->
                        <span class="dot--alert-1" *ngIf="notifications.length"></span>
                    </div>

                    <div class="tooltips" *ngIf="permSv.hasPermFromV2(allPermissions.others.access_clico)">
                        <div (click)="getUrlClico()" [title]="!clicoLoading && clicoError && clicoError !== '' ? clicoError : ''">
                            <a href="javascript:void(0)" class="icons--nav" *ngIf="!clicoLoading">
                                <img src="./assets/img/school.svg" class="img-fluid icon-nav" width="24">
                            </a>
                            <span class="tooltiptext">Formación Clico</span>
                            <!-- aviso visual de alerta -->
                            <span class="dot--error cursor-pointer" *ngIf="!clicoLoading && clicoError && clicoError !== ''"></span>
                        </div>
                        <a href="javascript:void(0)" class="icons--nav" *ngIf="clicoLoading">
                            <img src="./assets/img/spinner-white.svg" class="img-fluid icon-nav" width="24">
                        </a>
                    </div>

                    <div class="tooltips">
                        <a href="javascript:void(0)" (click)="openContactPdvDialog()" class="icons--nav">
                            <img src="./assets/img/contact.svg" class="img-fluid icon-nav" width="24" fill="white">
                        </a>
                        <span class="tooltiptext">Contacto</span>
                    </div>
                </div>
            </div>

            <!-- Alerta full-width -->
            <div class="col-xl-12" *ngIf="notifications.length && today.isBefore(notFinishDate)">
                <div class="alert alert--sidebar--2 alert-dismissible fade show mobile--al--sidebar mt-0 px-0" role="alert">
                    <img src="./assets/img/bell1.svg" class="img-fluid avisos--sidebar--icon me-2" width="16">
                    Tienes <a href="javascript:void(0)" (click)="openNotificationsDialog()" class="orange"><b>{{ notifications.length }}</b></a> comunicados pendientes

                    <button type="button" class="close pe-1" data-bs-dismiss="alert" aria-label="Close">
                        <img src="./assets/img/x-white.svg" width="18" class="close-aviso--1">
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container" *ngIf="pdv && !showRedeemPoints">
    <mat-tab-group #tabGroup dynamicHeight disableRipple class="md-no-animation" [selectedIndex]="currentSelectedIndex" (selectedTabChange)="tabChanged($event)">
        <mat-tab [label]="tabHome.CONTRATACIONES">
            <ng-template mat-tab-label>
                <i-feather name="grid" class="icon--fe"></i-feather>
                Contrataciones
            </ng-template>
            <app-hm-hiring [tabGroup]="tabGroup" [pdv]="pdv" [dataPoints]="dataPoints"></app-hm-hiring>
        </mat-tab>

        <mat-tab [label]="tabHome.COBERTURA" *ngIf="permSv.hasPermFromV2(allPermissions.rep_movil.permite_ventas) || permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
            <ng-template mat-tab-label>
                <i-feather name="map-pin" class="icon--fe"></i-feather>
                Cobertura
            </ng-template>
            <app-hm-coverage [initCoverage]="changeTab.asObservable()" [pdv]="pdv"></app-hm-coverage>
        </mat-tab>

        <mat-tab [label]="tabHome.OPERACIONES" *ngIf="permSv.hasPermFromV2(allPermissions.others.access_night)">
            <ng-template mat-tab-label>
                <i-feather name="users" class="icon--fe"></i-feather>
                Operaciones
            </ng-template>
            <app-hm-operations [initOperation]="changeTab.asObservable()"></app-hm-operations>
        </mat-tab>

        <mat-tab [label]="tabHome.OPERACIONES_POSVENTA" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional) && permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)"> <!-- || permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) -->
            <ng-template mat-tab-label>
                <i-feather name="user-check" class="icon--fe"></i-feather>
                Operaciones posventa
            </ng-template>
            <app-hm-operations-posventa [initOperationPostpago]="changeTab.asObservable()"></app-hm-operations-posventa>
        </mat-tab>

        <mat-tab [label]="tabHome.VENTAS">
            <ng-template mat-tab-label>
                <i-feather name="pie-chart" class="icon--fe"></i-feather>
                Mis ventas
            </ng-template>
            <app-hm-sales [tabGroup]="tabGroup" [initSales]="changeTab.asObservable()"></app-hm-sales>
        </mat-tab>

        <mat-tab [label]="tabHome.COMISIONES" *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_permitir) && pdv?.info?.tipo_vista !== PDV_MULTIMARCA && this.permSv.hasPermFromV2(allPermissions.others.access_night)">
            <ng-template mat-tab-label>
                <i-feather name="plus-circle" class="icon--fe"></i-feather>
                Mis comisiones
            </ng-template>
            <app-hm-commissions [pdv]="pdv"></app-hm-commissions>
        </mat-tab>

        <mat-tab [label]="tabHome.TARIFAS">
            <ng-template mat-tab-label>
                <i-feather name="list" class="icon--fe"></i-feather>
                Tarifas
            </ng-template>
            <app-hm-rates [initRates]="changeTab.asObservable()"></app-hm-rates>
        </mat-tab>

        <mat-tab [label]="tabHome.PUNTOS" *ngIf="permSv.hasPermFromV2(allPermissions.others.access_points) && this.permSv.hasPermFromV2(allPermissions.others.access_night)">
            <ng-template mat-tab-label>
                <i-feather name="gift" class="icon--fe"></i-feather>
                ¡Atrévete!
            </ng-template>
            <app-home-points #tabPoints [content]="showContentPoints" [pdv]="pdv" (onGoToRedeemPoints)="goToRedeemPoints($event)" (onUpdatePoints)="updatePoints($event)"></app-home-points>
        </mat-tab>

    </mat-tab-group>

    <div class="row">
        <div class="col-xl-12">
            <hr class="mt-5">

            <div class="footer--hr">
                <p>
                    <span class="me-3">Usuario: {{ pdv.info.username }}</span>
                    <span class="me-3" *ngIf="lastAccess">Última conexión: {{ lastAccess }}</span>
                    <span class="me-3">Versión Digo: {{ version }}</span>
                </p>
            </div>
        </div>
    </div>
</div>

<app-point-order *ngIf="showRedeemPoints" [reward]="rewardSelected" [amountSelected]="amountSelected" [availablePoints]="availablePoints" [pdv]="pdv" (onGoBack)="goBackHomePoints($event)"></app-point-order>
<br><br><br>
